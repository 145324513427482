import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "cccbb383",
  "date": "2022-01-15"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`cccbb383`}</h1>
    <h2>{`Summary`}</h2>
    <p>{`This disc was my specific exchange in the MiniDisc Mix, with 2 recipients.
Each got an identical copy. Was trying to go for something closer to deep cuts
from my library here.`}</p>
    <h2>{`Tracks`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=QqxVhzqdjBs"
        }}>{`FAX Me - Yoko Kanno`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=jSc9dCEsOuo"
        }}>{`Swim - Madonna`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=4k7D8dhBrXU"
        }}>{`Running One - Tykwer, Klimek, Heil`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=NLTOXjb2Tvc"
        }}>{`Back And Forth - Doctor Steel`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=_vexFN5hpwI"
        }}>{`Bits Of Bone - The Delgados`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=RNVjZZ0fkzw"
        }}>{`Live & Direct - Sugar Ray`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=BpBIuhO5QQs"
        }}>{`Flute Loop - Beastie Boys`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=fG5fKpU951U"
        }}>{`Out Of Sight - Smash Mouth`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=SeI3DXu7hHo"
        }}>{`Fashionably Uninvited - Mellowdrone`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=AXMOMfQglho"
        }}>{`Don't Hurt Yourself - Marillion`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://youtu.be/wKx_PH3M0ho?t=260"
        }}>{`Rebel Rebel - David Bowie`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=7q2HAefWrBA"
        }}>{`The Reptiles And I - Shriekback`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=WIIDvMxutSE"
        }}>{`Wave Motion Gun - Marcy Playground`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=EfhVxVefNhk"
        }}>{`Beat It `}{`[`}{`Moby's Sub Mix`}{`]`}{` - Michael Jackson`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=ZoysjE6kSRY"
        }}>{`Digital Love `}{`[`}{`Boris Dlugosh Remix`}{`]`}{` - Daft Punk`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=Pib8eYDSFEI"
        }}>{`Crystalised - The Xx`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=U2xVTE-2eik"
        }}>{`Makka na Bara to Gin Tonic - Sakai & Mizumori`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=IrAEE5w7SJY"
        }}>{`Stay Entertained - Stellastarr`}{`*`}</a></li>
    </ol>
    <h2>{`Mixing/Sourcing Notes`}</h2>
    <ul>
      <li parentName="ul">{`I have some questionable things in my media library.`}</li>
      <li parentName="ul">{`I'll admit getting anything out of me in January is a terrible idea at the
best of times, a lot of family birthdays in here.`}</li>
      <li parentName="ul">{`I don't remember how the discs are titled! I built this from the m3u file
I had exported.`}</li>
      <li parentName="ul">{`Back and Forth has `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=yCKkcex6_Hg"
        }}>{`a music video`}</a>{`.
It's a thing.`}</li>
      <li parentName="ul">{`Apparently, so does `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=7KaZSj262Tc"
        }}>{`Don't Hurt Yourself`}</a></li>
      <li parentName="ul">{`David Bowie's Story Tellers is awesome and everyone should listen to that.`}</li>
      <li parentName="ul">{`I swear the "Beat It" remix is something out of Mario Kart.`}</li>
      <li parentName="ul">{`But now I really just want to play Katamari Damacy.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      